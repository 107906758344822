import React, { useState } from 'react';
import data from './TP Specs';


const people = [
	{
		"name": "Man",
		"src": "aiga_man.svg",
		"alt": "M",
		"class": "male",
		"usageFactor": 0.6
	 },
	 {
		"name": "Woman",
		"src": "aiga_woman.svg",
		"alt": "F",
		"class": "female",
		"usageFactor": 1.4
	 },
	 {
		"name": "Boy",
		"src": "aiga_boy.svg",
		"alt": "m",
		"class": "male",
		"usageFactor": .4
	 },
	 {
		"name": "Girl",
		"src": "aiga_girl.svg",
		"alt": "f",
		"class": "female",
		"usageFactor": .8
	 }
]

const atHomeFactor = [.7, 1, 1.4];

export default class WipeAway extends React.Component {

	constructor(props) {
		
		super(props);
	
		this.state = { 
			radioId: '',
			typedNumber: '',
			defaultValue: '',
			brandName: '',	// Charmin, Cottonelle, White Cloud, etc
			paperStyle: '',		// soft, strong, etc
			rollSize: "",	// regular, double, mega, etc
			rollsInPack: 0,
			rollsPerPack: "",
			price: 1.00,
			selecting: 1,
			household: [1],	// 1 = Adult Male, 2 = Adult Female, 3 = Child Male, 4 = Child Females
			atHomeFactor: 1	// 0 = Not a lot, 1 = usual amount, 2 = Home a Lot (WFH)
		};

	}


	showBrandSelection = (event) =>
	{
		this.setState({selecting: 1});
		this.setState({brandName: ""});
		this.setState({paperStyle: ""});
		this.setState({rollSize: ""});
		this.setState({rollsInPack: 0});
	}
	handleBrandClick = (event) => {
		if(this.state.brandName === event.target.value){
			this.setState({selecting: 2});
		}
	}
	handleBrandChange = (event) => {

		// set selected brand
		if(this.state.brandName !== event.target.value)
		{
			this.setState({paperStyle: ""});
			this.setState({rollSize: ""});
			this.setState({rollsInPack: 0});
		}
		this.setState({
			brandName: event.target.value
		});

		this.setState({selecting: 2});

		/*
		// If we are selected a brand....
		if(this.state.selecting === 1)
		{
			this.setState({selecting: 2});
		}
		else if (this.state.selecting > 1)
		{
			this.setState({selecting: 1});
		}
		*/
	}

	showStyleSelection = (event) =>
	{
		if(!this.state.brandName )
		return;
		this.setState({selecting: 2});
		this.setState({paperStyle: ""});
		this.setState({rollSize: ""});
		this.setState({rollsInPack: 0});
	}
	handleStyleClick = (event) => {
		if(this.state.styleName === event.target.value){
			this.setState({selecting: 3});
		}
	}
	handleStyleChange = (event) => {
		if(this.state.paperStyle !== event.target.value)
		{
			this.setState({rollSize: ""});
			this.setState({rollsInPack: 0});
		}
		this.setState({
			paperStyle: event.target.value,
		})

		this.setState({selecting: 3});
	}

	showRollSizeSelection = (event) =>
	{
		if (!this.state.paperStyle)
			return;
		this.setState({selecting: 3});
		this.setState({rollSize: ""});
		this.setState({rollsInPack: 0});
	}
	handleRollSizeChange = (event) => {
		if(this.state.rollSize !== event.target.value)
		{
			this.setState({rollsInPack: 1});	// set a default of 1 roll
		}
		this.setState({
			rollSize: event.target.value
		})

		this.setState({selecting: 4});
	}

	showRollsInPackSelection = (event) =>
	{
		if (!this.state.rollSize)
			return;
		this.setState({selecting: 4});
		//this.setState({rollsInPack: 1});
	}
	handleRollsInPackChange = (event) => {
		//alert(this.state.rollsInPack + " changed to " + event.target.value);
		this.setState({
			rollsInPack: event.target.value
		})

		this.setState({selecting: 5});
	}

	showInstruction = () => {
		let instruction = (this.state.selecting === 1 ? "Choose Brand" : 
						(this.state.selecting === 2 ? "Choose Style" :
							(this.state.selecting === 3 ? "Choose Roll Size" :
								(this.state.selecting === 4 ? "Choose Roll Count" :
									(this.state.selecting === 5 ? "Set the Price and see the Value!" : "")))));

		return (
			<div className="instruction">
				{instruction}
			</div>
		)
	}
	
	showStyleOptions = () => {
		let styleOptions = [];
		if(this.state.selecting === 2){
		//		if(this.state.brandName){
			data.map((brand, index) => {
				if(brand.brand === this.state.brandName) {
					brand.style.map((styleName, index) => {
						styleOptions.push(styleName.name)
					})
				}
			})
		}
		return (
			<div className={styleOptions.length > 0 ? 'second-option__set' :''}>
				{
					styleOptions.map((value, key) => {
						return (

							<div className={this.state.paperStyle === value ? 'active-second second-option__individual' : 'second-option__individual'} key={key}>
								<input
									type="radio"
									id={value}
									value={value}
									name='styleoption'
									checked={ this.state.paperStyle === value }
									onChange={ this.handleStyleChange }
								/>
								<label htmlFor={value}>
									{value}
								</label>
							</div>
						)

					})
				}
			</div>
		)
	}

	/*
	rollMath = () => {
		let rollOptions = [];
		let rollSizeOptions = [];
		data.map((brand, index) => {
			if(brand.brand === this.state.brandName) {
				brand.style.map((style, index) => {
					if(style.name === this.state.paperStyle){
						style.rollSize.map((rollSize) => {
							rollOptions.push(rollSize)
							if(rollSize.name === this.state.rollSize){
								rollSize.packSizes.map(numberInPack => {
									rollSizeOptions.push(numberInPack)
								})
							}
							// Do Math?

						})
					}
				})
			}
		})
		return this.state.brandName;
	}
	*/

	showRollSizeOptions = () => {
		let rollOptions = [];
		if(this.state.selecting === 3) {
//		if(this.state.paperStyle){
			data.map((brand, index) => {
				if(brand.brand === this.state.brandName) {
					brand.style.map((style, index) => {
						if(style.name === this.state.paperStyle){
							style.rollSize.map((rollSize) => {
								rollOptions.push(rollSize)
							})
						}
					})
				}
			})
		}

		return (

			<div>
				<div className={rollOptions.length > 0 ? 'third-option__set' :''}>

					{
						rollOptions.map((rollSizeOptionObject, key) => {

							return (
								<div className={this.state.rollSize === rollSizeOptionObject.name ? 'active-third third-option__individual' : 'third-option__individual'} >
									<input
										type="radio"
										id={rollSizeOptionObject.name}
										value={rollSizeOptionObject.name}
										name='rollsizeoption'
										checked={ this.state.rollSize === rollSizeOptionObject.name }
										onChange={ this.handleRollSizeChange }
									/>
									<label htmlFor={rollSizeOptionObject.name}>
										{rollSizeOptionObject.name}
										<br/>
										<span Style="font-size:1rem">{rollSizeOptionObject.claimedEquiv}x</span>
									</label>
								</div>
							)

						})
					}

				</div>

			</div>

		)
	}

	showRollsInPackOptions = () => {
		let rollOptions = [];
		let rollSizeOptions = [];
		if(this.state.selecting === 4) {
//		if(this.state.paperStyle){
			data.map((brand, index) => {
				if(brand.brand === this.state.brandName) {
					brand.style.map((style, index) => {
						if(style.name === this.state.paperStyle){
							style.rollSize.map((rollSize) => {
								rollOptions.push(rollSize)
								if(rollSize.name === this.state.rollSize){
									rollSize.packSizes.map(numberInPack => {
										rollSizeOptions.push(numberInPack)
									});
									rollSizeOptions = [1,2,4,6,8,9,12,18,24,32,36];
								}
							})
						}
					})
				}
			})
		}

		return (

			<div>
				{/* Show known Packaged Roll Count options */}
				<div className={rollSizeOptions.length > 0 ? 'rolls-in-pack-option__set' :''}>
					{rollSizeOptions.map(element=> {
						return (
							<div className={this.state.rollsInPack === element ? 'active-rolls-in-pack rolls-in-pack-option__individual' : 'rolls-in-pack-option__individual'}>
								<input
									type="radio"
									id={element}
									value={element}
									name='rollsinpackoption'
									checked={ this.state.rollsInPack === element}
									onChange={this.handleRollsInPackChange}
								/>
								<label htmlFor={element}>
									{element}
								</label>
							</div>
						)
					})}
				</div>
			</div>
		)
	}

	showRollsInPackInput = () => {
		if(this.state.selecting === 4){
		return (
			<div>
				<input id="rollsInPack" className="rollsInPack-input" value={this.state.rollsInPack} onChange={e => this.setState({rollsInPack: e.target.value})} type="number" min="1" max="100" step="1" inputmode="numeric" pattern="[0-9]"></input>
				<label className="rollsInPack-label" htmlFor="rollsInPack">{this.state.rollsInPack === 1 ? "Roll " : "Rolls"}</label>
				<button disabled={this.state.selecting === 4} onClick={() => {this.setState({selecting: 5});}} id="rollsInPackConfirm" className="rollsInPack-confirm">&#x2713;</button>
			</div>
		);
		}
	}

	iterateAtHomeFactor = () =>
	{
		var newAtHomeFactor = (this.state.atHomeFactor + 1) % 3;

		this.setState({atHomeFactor: newAtHomeFactor});
	}

	showAtHomeSelector = () =>
	{
		return (
			<div><button onClick={this.iterateAtHomeFactor} className="at-home-selector-button">{this.state.atHomeFactor === 0 ? "Home a little" : (this.state.atHomeFactor === 1 ? "Home usually" : "Home a lot")}</button></div>
		);
	}

	addHouseholdMember = () =>
	{
		var add = (this.state.household.length > 0 ? this.state.household[this.state.household.length-1] : 1);
		let newHousehold = [ ...this.state.household ];
		newHousehold.push(add);
		this.setState({household: newHousehold});
	}

	removeHouseholdMember = () =>
	{
		if(this.state.household.length > 1)
		{
			let newHousehold = [ ...this.state.household ];
			newHousehold.pop();
			this.setState({household: newHousehold});
		}
	}

	iterateHouseholdMember = (index) =>
	{
		if(this.state.household.length > index)
		{
			let newHousehold = [ ...this.state.household ];
			var newValue = (newHousehold[index] % 4 + 1);
			newHousehold[index] = newValue;
			this.setState({household: newHousehold});

		}
	}

	showHouseholdSelector = () => {
		return (
			<div className="household-selector">
				{/* list household members */}
				{this.state.household.map((member, index) => {
					return (
						<img onClick={() => this.iterateHouseholdMember(index)} src={people[member-1].src} alt={people[member-1].alt} className={people[member-1].class} height="64px"/>
					)})
				}
				<div className="household-selector-buttons">
					<div className="household-selector-button"><button onClick={this.addHouseholdMember} title="Add household member">+</button></div>
					<div classname="household-selector-button"><button onClick={this.removeHouseholdMember} disabled={this.state.household.length === 1} title="Remove household member">-</button></div>
				</div>
			</div>
		);
	}

	householdUsageFactor = () => {
		var totalUsageFactor = this.state.household.reduce((a, member) => a + people[member-1].usageFactor, 0);
		return totalUsageFactor;
	}

	paperLongevity = (sheetsPerRoll) => {

		var totalUsage = (sheetsPerRoll * this.state.rollsInPack) / (this.householdUsageFactor() * 0.57 * 150 * atHomeFactor[this.state.atHomeFactor]);

		return totalUsage;
	}

	showCalculations = () => {
		let rollOptions = [];
		let rollSizeOptions = [];
		if(this.state.selecting === 5){
			data.map((brand, index) => {
				if(brand.brand === this.state.brandName) {
					brand.style.map((style, index) => {
						if(style.name === this.state.paperStyle){
							style.rollSize.map((rollSize) => {
								rollOptions.push(rollSize)
								if(rollSize.name === this.state.rollSize){
									rollSize.packSizes.map(numberInPack => {
										rollSizeOptions.push(numberInPack)
									})
									rollSizeOptions = [1,2,4,6,8,9,12,18,24,32,36];
								}
							})
						}
					})
				}
			})
		}

		return (
			<div>
				<div className={rollOptions.length > 0 ? 'roll-math' :''}>
					{
						rollOptions.map((rollOptionObject) => {
							if  (rollOptionObject.name === this.state.rollSize) {
								return (
									<div>
										{/*  slider for # of rolls in pack}
										<div>
											<label>{this.rollsPerPack}Foo</label>
											<input type="range" min="1" max="100" id="numRollsInPackSlider" onInput={this.handleRollsInPackChange}></input>
										</div>
										*/}
										<div className="price">
											Price $<input id="price" className="price-input" value={this.state.price} onChange={e => this.setState({price: e.target.value})} type="number" min="0.01" max="200" step="0.01" inputmode="numeric" pattern="^(\d*\.)?\d+$"/>
											{/*<CurrencyInput id="packagePrice" name="price" placeholder="$1.00" step="0.01" defaultValue="$1.00" fixedDecimalLength="2" allowNegativeValue="false"  onChange={this.handlePackagePriceChange}/>*/}
										</div>
										<div className="roll-math-detail">
											{/*}
											<input
												type="radio"
												id={rollOptionObject.name}
												value={rollOptionObject.name}
												name='rollsizeoption'
												checked={ this.state.rollSize === rollOptionObject.name }
												onChange={ this.handleRollSizeChange }
											/>
											*/}
											
											<table class="results-table roll-math-text">
												<tbody className="roll">
													<tr>
														<td class="results-table-value">{this.state.rollsInPack}</td>
														<td class="results-table-text">{rollOptionObject.ply}-ply Rolls</td>
													</tr>
													<tr>
														<td class="results-table-value">{rollOptionObject.sheetsPerRoll}</td>
														<td class="results-table-text">sheets each</td>
													</tr>
													<tr>
														<td class="results-table-value">{rollOptionObject.sheetsPerRoll * this.state.rollsInPack}</td>
														<td class="results-table-text">total sheets</td>
													</tr>
													<tr>
														<td class="results-table-value">{(rollOptionObject.sheetsPerRoll * this.state.rollsInPack / this.state.price).toFixed(2)}</td>
														<td class="results-table-text">sheets per $</td>
													</tr>
												</tbody>
											</table>
											
										</div>
										{this.showHouseholdSelector()}{this.showAtHomeSelector()}
										Expected to last {this.paperLongevity(rollOptionObject.sheetsPerRoll).toFixed(1)} days
									</div>
								)
							}
						})
					}

				</div>

			</div>

		)
	}

	showSelectionArea = () => {
		if(this.state.selecting > 0)
		{
			return (
				<div className="selection">
					<div className={"selection-item brand-choice" + (this.state.brandName ? "" : " unchosen")} >
						<label htmlFor={this.state.brandName} class="brand-choice-label">
							<img onClick={this.showBrandSelection} src={`${this.state.brandName}` + `.png`} alt={`${this.state.brandName}`} className="brandImg"/>
							{this.state.brandName ? "" : " Choose a Brand to Get Started"}
						</label>
					</div>
					<div className="selection-properties">
						<div onClick={this.showStyleSelection} className={"selection-property style-choice" + (this.state.paperStyle.length > 0 ? " chosen" : " unchosen")}>{this.state.paperStyle ? this.state.paperStyle : "Style"}</div>
						<div onClick={this.showRollSizeSelection} className={"selection-property rollSize-choice" + (this.state.rollSize.length > 0 ? " chosen" : " unchosen")}>{this.state.rollSize ? this.state.rollSize : "Roll Size"}</div>
						<div onClick={this.showRollsInPackSelection} className={"selection-property rollsInPack-choice"+ (this.state.rollsInPack > 0 ? " chosen" : " unchosen")}>{this.state.rollsInPack > 0 ? this.state.rollsInPack + (this.state.rollsInPack > 1 ? " Rolls": " Roll") : "# of Rolls"}</div>
					</div>
				</div>
			)
		}

	}


	render() {

		const brandOptionButtons = data.map((string,index) => {
			if(this.state.selecting === 1)
			{
				return (
					<div className={this.state.brandName === string.brand ? 'active-brand brand-choices' : (this.state.selecting === 1 ? 'brand-choices' : 'brand-choices-hidden')} key={index}>
							<input
								type="radio"
								id={string.brand}
								value={string.brand}
								name='tpoption'
								checked={ this.state.brandName === string.brand }
								onChange={ this.handleBrandChange }
								//onClick={this.handleBrandClick}
							/>
						<label htmlFor={string.brand} className="brand-label">
							<img src={`${string.brand}` + `.png`} alt={`${string.brand}`} className="brandImg"/>
						</label>
					</div>
				)	
			}
		});

		return (

			<div className="App">
				<div className="wipe-away__title"><img src="WipeAwayLogo.png" className="wipe-away__logo" alt="Wipe Away The Confusion!" /></div>

				{this.showSelectionArea()}
				
				<div className="instruction">
					{this.showInstruction()}
				</div>
				<div className="brands">
					{brandOptionButtons}
				</div>
				<div>
					{this.showStyleOptions()}
				</div>
				<div>
					{this.showRollSizeOptions()}
				</div>
				<div>
					{this.showRollsInPackOptions()}
					{this.showRollsInPackInput()}
				</div>
				<div>
					{this.showCalculations()}
				</div>

			</div>

		);

	}
	
}
