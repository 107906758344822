export const data = [
	{
		"brand": "Aria",
		"style": [
			{
				"name": "Premium",
				"rollSize": [
					{
						"name": "-",
						"ply": 2,
						"width": 4,
						"length": 4,
						"sheetsPerRoll": 308,
						"claimedEquiv": 1,
						"packSizes": [1]
					}
				]
			},
		]
	},
	{
		"brand": "AngelSoft",
		"style": [
			{
				"name": "-",
				"rollSize": [
					{
						"name": "Double",
						"ply": 2,
						"width": 4,
						"length": 4,
						"sheetsPerRoll": 264,
						"claimedEquiv": 2,
						"packSizes": [1]
					},
					{
						"name": "Regular",
						"ply": 2,
						"width": 4,
						"length": 4,
						"sheetsPerRoll": 132,
						"claimedEquiv": 1,
						"packSizes": [1]
					},
					{
						"name": "Mega",
						"ply": 2,
						"width": 0,
						"length": 0,
						"sheetsPerRoll": 484,
						"claimedEquiv": 4,
						"packSizes": [1]
					}
				]
			},
		]
	},
	{
		"brand": "Charmin",
		"style": [
			{
				"name": "Essentials Soft",
				"rollSize": [
					{
						"name": "Giant",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 200,
						"claimedEquiv": 2.25,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Essentials Strong",
				"rollSize": [
					{
						"name": "Giant",
						"ply": 1,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 300,
						"claimedEquiv": 2.583333,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Sensitive",
				"rollSize": [
					{
						"name": "Mega",
						"ply": 2,
						"width": 3.92,
						"length": 3.95,
						"sheetsPerRoll": 300,
						"claimedEquiv": 4,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Ultra Soft",
				"rollSize": [
					{
						"name": "Double",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 142,
						"claimedEquiv": 2,
						"packSizes": [1]
					},
					{
						"name": "Double Plus",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 162,
						"claimedEquiv": 2.25,
						"packSizes": [1]
					},
					{
						"name": "Extra Value",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 132,
						"claimedEquiv": 1,
						"packSizes": [1]
					},
					{
						"name": "Mega",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 284,
						"claimedEquiv": 4,
						"packSizes": [1]
					},
					{
						"name": "Mega Plus",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 326,
						"claimedEquiv": 4.5,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Ultra Strong",
				"rollSize": [
					{
						"name": "Double",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 154,
						"claimedEquiv": 2,
						"packSizes": [1]
					},
					{
						"name": "Extra Value",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 132,
						"claimedEquiv": 1,
						"packSizes": [1]
					},
					{
						"name": "Mega",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 308,
						"claimedEquiv": 4,
						"packSizes": [1]
					},
					{
						"name": "Mega Plus",
						"ply": 2,
						"width": 3.92,
						"length": 4,
						"sheetsPerRoll": 352,
						"claimedEquiv": 4.5,
						"packSizes": [1]
					}
				]
			},
		]
	},
	{
		"brand": "Cottonelle",
		"style": [
			{
				"name": "CleanCare",
				"rollSize": [
					{
						"name": "Big",
						"ply": 1,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 150,
						"claimedEquiv": 1,
						"packSizes": [1]
					},
					{
						"name": "Family Roll",
						"ply": 1,
						"width": 0,
						"length": 0,
						"sheetsPerRoll": 250,
						"claimedEquiv": 1,
						"packSizes": [1]
					},
					{
						"name": "Double",
						"ply": 1,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 190,
						"claimedEquiv": 2,
						"packSizes": [1]
					},
					{
						"name": "Mega",
						"ply": 1,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 380,
						"claimedEquiv": 4,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "GentleCare",
				"rollSize": [
					{
						"name": "Mega",
						"ply": 1,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 408,
						"claimedEquiv": 4,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Ultra ComfortCare",
				"rollSize": [
					{
						"name": "Big",
						"ply": 2,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 126,
						"claimedEquiv": 1,
						"packSizes": [1]
					},
					{
						"name": "Double",
						"ply": 2,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 154,
						"claimedEquiv": 2,
						"packSizes": [1]
					},
					{
						"name": "Mega",
						"ply": 2,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 308,
						"claimedEquiv": 4,
						"packSizes": [1]
					}
				]
			},
		]
	},
	{
		"brand": "Quilted Northern",
		"style": [
			{
				"name": "Ultra Plush",
				"rollSize": [
					{
						"name": "Double",
						"ply": 3,
						"width": 0,
						"length": 0,
						"sheetsPerRoll": 154,
						"claimedEquiv": 2,
						"packSizes": [1]
					},
					{
						"name": "Mega",
						"ply": 3,
						"width": 4,
						"length": 4,
						"sheetsPerRoll": 330,
						"claimedEquiv": 4,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Ultra Soft & Strong",
				"rollSize": [
					{
						"name": "Double",
						"ply": 2,
						"width": 4,
						"length": 4,
						"sheetsPerRoll": 176,
						"claimedEquiv": 2,
						"packSizes": [1]
					},
					{
						"name": "Mega",
						"ply": 2,
						"width": 4,
						"length": 4,
						"sheetsPerRoll": 352,
						"claimedEquiv": 4,
						"packSizes": [1]
					}
				]
			},
		]
	},
	{
		"brand": "Scott",
		"style": [
			{
				"name": "1000 Sheets",
				"rollSize": [
					{
						"name": "Regular",
						"ply": 1,
						"width": 4.1,
						"length": 3.7,
						"sheetsPerRoll": 1000,
						"claimedEquiv": 1,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Extra Soft",
				"rollSize": [
					{
						"name": "Big",
						"ply": 1,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 202,
						"claimedEquiv": 1,
						"packSizes": [1]
					},
					{
						"name": "Mega",
						"ply": 1,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 528,
						"claimedEquiv": 4,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Extra Soft Dual Textured",
				"rollSize": [
					{
						"name": "Mega",
						"ply": 1,
						"width": 4.2,
						"length": 4,
						"sheetsPerRoll": 400,
						"claimedEquiv": 3,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Extra Soft Dri",
				"rollSize": [
					{
						"name": "Double",
						"ply": 1,
						"width": 4.2,
						"length": 4,
						"sheetsPerRoll": 264,
						"claimedEquiv": 1,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Rapid-Dissolving",
				"rollSize": [
					{
						"name": "Regular",
						"ply": 1,
						"width": 3.82,
						"length": 4,
						"sheetsPerRoll": 264,
						"claimedEquiv": 1,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Tube Free",
				"rollSize": [
					{
						"name": "",
						"ply": 0,
						"width": 0,
						"length": 0,
						"sheetsPerRoll": 0,
						"claimedEquiv": 2,
						"packSizes": [1]
					}
				]
			},
		]
	},
	{
		"brand": "Seventh Generation",
		"style": [
			{
				"name": "100% Recycled, Whitened",
				"rollSize": [
					{
						"name": "Double",
						"ply": 2,
						"width": 4,
						"length": 4,
						"sheetsPerRoll": 300,
						"claimedEquiv": 2,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Natural Unbleached",
				"rollSize": [
					{
						"name": "",
						"ply": 2,
						"width": 4,
						"length": 3.7,
						"sheetsPerRoll": 400,
						"claimedEquiv": 1,
						"packSizes": [1]
					}
				]
			},
		]
	},
	{
		"brand": "White Cloud",
		"style": [
			{
				"name": "Ultra Strong & Soft",
				"rollSize": [
					{
						"name": "Mega",
						"ply": 2,
						"width": 4,
						"length": 4,
						"sheetsPerRoll": 308,
						"claimedEquiv": 4,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Ultra 3 ply",
				"rollSize": [
					{
						"name": "Giant",
						"ply": 3,
						"width": 3.9,
						"length": 4,
						"sheetsPerRoll": 231,
						"claimedEquiv": 2.5,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "Green Earth",
				"rollSize": [
					{
						"name": "Triple",
						"ply": 2,
						"width": 3.9,
						"length": 4,
						"sheetsPerRoll": 296,
						"claimedEquiv": 3,
						"packSizes": [1]
					}
				]
			},
			{
				"name": "2 ply Comfort Soft  Strong",
				"rollSize": [
					{
						"name": "Triple",
						"ply": 2,
						"width": 3.9,
						"length": 4,
						"sheetsPerRoll": 400,
						"claimedEquiv": 3,
						"packSizes": [1]
					}
				]
			},
		]
	}
]

export default data;