import React from 'react';
import './App.css';
import WipeAway from './WipeAway';

/* updated to latest libraries on November 9, 2022 */

function App() {
  return (
	  <WipeAway />
  )
}

export default App;
